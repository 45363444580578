import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/index/index'),
  },









  //这个路由放在最后，新增路由写在上面，此处仅作测试版本调试使用
  {
  path: '/',
  name: 'Login',
  component: () => import('@/views/login')
  },
  // {
  //   path: '/green',
  //   name: 'JKDZ',
  //   component: () => import('@/views/JKDZ')
  // },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {

   if(to.path === '/' || to.path==='/green') return next();
  // const token = window.sessionStorage.getItem('token');
  // if(!token) return next('/');
  next();

})

export default router

