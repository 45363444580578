import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import {
  getToken,
  getUserID,
  getStaffName,
  getRoleID,
  getDeptID,
} from '@/util/token'

export default new Vuex.Store({
  //定义了应用状态的数据结构，可以在这里设置默认的初始状态。
  state: {
    User_ID : getUserID(),
    Role_ID : getRoleID(),
    Dept_ID : getDeptID(),
    Staff_Name: getStaffName(),
    token: getToken(),
  },
  //是唯一更改 store 中状态的方法，且必须是同步函数。
  mutations: {
    setUser(state, data) {
      state.User_ID = data;
      sessionStorage.setItem("User_ID", data)
    },
    setRole(state, data) {
      state.Role_ID = data;
      sessionStorage.setItem("Role_ID", data)
    },
    setDept(state, data) {
      state.Dept_ID = data;
      sessionStorage.setItem("Dept_ID", data)
    },
    setStaff(state, data) {
      state.Staff_Name = data;
      sessionStorage.setItem("Staff_Name", data)
    },
    setToken(state, data) {
      state.token = data;
      sessionStorage.setItem("token", data)
    },
  },
  //用于提交 mutation，而不是直接变更状态，可以包含任意异步操作。
  actions: {
    setUser({ commit }, data) {
      commit('setUser', data);
    },
    setRole({ commit }, data) {
      commit('setRole', data);
    },
    setDept({ commit }, data) {
      commit('setDept', data);
    },
    setStaff({ commit }, data) {
      commit('setStaff', data);
    },
    setToken({ commit }, data) {
      commit('setToken', data);
    },
  },
  //可以将 store 分割成模块（module）。每个模块拥有自己的 state、mutation、action、getter、甚至是嵌套子模块——从上至下进行同样方式的分割
  modules: {
  },
  getters: {
    token: (state) => state.token,
    User_ID : (state) => state.User_ID,
    Role_ID :(state) => state.Role_ID,
    Dept_ID : (state) => state.Dept_ID,
    Staff_Name: (state) => state.Staff_Name,
  }
})
