export function getToken() {
    return sessionStorage.getItem("token")
}


export function getUserID() {
    return sessionStorage.getItem("User_ID")
}


export function getStaffName() {
    return sessionStorage.getItem("Staff_Name")
}

export function getRoleID() {
    return sessionStorage.getItem("Role_ID")
}


export function getDeptID() {
    return sessionStorage.getItem("Dept_ID")
}